export class PayerDetailsInput {
    constructor({
                    amount = '',
                    customerFullName = '',
                    customerEmail = ''
                }) {
        this.amount = amount;
        this.customer_name = customerFullName;
        this.customer_email = customerEmail;
    }
}

export class PayerDetails {
    constructor({
                    amount = '',
                    customerFullName = '',
                    customerEmail = '',
                    smartLink = '',
                    qrCodeLink = ''
                }) {
        this.amount = amount;
        this.customerFullName = customerFullName;
        this.customerEmail = customerEmail;
        this.smartLink = smartLink;
        this.qrCodeLink = qrCodeLink;
    }
}

export const INITIAL_PAYER_DETAILS = new PayerDetailsInput({
    amount: '',
    customer_name: '',
    customer_email: ''
});