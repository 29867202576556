export class flexInputDetails {
  constructor({
    amount = ''
  }) {
    this.amount = amount;
  }
}

export const INITIAL_FLEX_DETAILS = new flexInputDetails({
    amount: ''
});