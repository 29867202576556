import React from "react";

function Loading() {
  return (
    <div className="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
      <div uk-spinner="ratio: 3" />
      <div>Verifying your identity</div>
    </div>
  )
}

export default Loading
