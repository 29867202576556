import React from "react";

function Error500() {
  return (
    <div className="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
      <h1>500</h1>
      <span>Something wrong happened.</span>
    </div>
  )
}

export default Error500
