import React, {useCallback, useEffect, useState} from "react";
import * as Sentry from "@sentry/react";

function Recaptcha({ setScriptLoaded, recaptchaRef, actionName }) {
  const [scriptInitialized, setScriptInitialized] = useState(false)

  const loadScript = useCallback(async () => {
    setScriptLoaded();
  }, [setScriptLoaded])

  const handleLoadErrorEvent = useCallback((event) => {
    Sentry.captureException(event.error);
  }, [])

  const getRecaptchaToken = useCallback(async () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        try {
          window.grecaptcha.execute(window.config.RECAPTCHA_PUBLIC_KEY, {
            action: actionName
          }).then((token) => {
            return resolve(token);
          }).catch(function (error) {
            return reject(error);
          });
        } catch(error) {
          return reject(error);
        }
      })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Ensure there is no case where we inadvertently load the script twice
    if (scriptInitialized) {
      return;
    }
    setScriptInitialized(true)

    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${window.config.RECAPTCHA_PUBLIC_KEY}`
    script.addEventListener("load", loadScript)
    script.addEventListener("error", handleLoadErrorEvent)
    document.body.appendChild(script)
    recaptchaRef.current = getRecaptchaToken
  }, [scriptInitialized, recaptchaRef, getRecaptchaToken, setScriptInitialized, loadScript, handleLoadErrorEvent])

    return (
        <div className="g-recaptcha" data-sitekey={window.config.RECAPTCHA_PUBLIC_KEY} data-size="invisible" />
    )
}

export default Recaptcha;