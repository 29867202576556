import {useParams} from "react-router-dom";
import Flinks from "./flinks/flinks";
import React, {useCallback, useState} from "react";
import usePaymentRequest from "../../common/hooks/usePaymentRequest";
import Recaptcha from "../../components/recaptcha/recaptcha";
import Loading from "../../loading";
import Error404 from "../../error/error404";
import Error500 from "../../error/error500";
import EFTPaymentSuccess from "../components/common/eft-payment-success";
import EFTPaymentFailure from "../components/common/eft-payment-failure";


const EFTPayment = () => {
  const { shortCode } = useParams();
  const recaptchaRef = React.useRef(null);
  const [paymentRequestErrorCode, setPaymentRequestErrorCode] = useState(null);
  const [isFlinksError, setIsFlinksError] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const {isLoading, paymentRequest} = usePaymentRequest(shortCode, setPaymentRequestErrorCode, recaptchaLoaded, recaptchaRef);

  const setScriptLoaded = useCallback(() => {
    setRecaptchaLoaded(true)
  }, [setRecaptchaLoaded])

  const tags = {
    paymentRequestId: paymentRequest?.id
  }

  if(paymentRequestErrorCode === 404) {
    return <Error404 />
  }

  if(paymentRequestErrorCode) {
    return <Error500 />
  }

  if (isFlinksError){
    return <EFTPaymentFailure paymentRequest={paymentRequest}/>
  }

  if (isComplete){
    return <EFTPaymentSuccess paymentRequest={paymentRequest}/>
  }

  return <>
    {isLoading ? (<Loading/>) : (<Flinks headerEnable={true} accountSelectorEnable={true} tags={tags} merchantTermsUrl={paymentRequest.eftTermsAndConditionsUrl} onComplete={() => setIsComplete(true)} onError={() => setIsFlinksError(true)}/>)}
    <Recaptcha recaptchaRef={recaptchaRef}
               setScriptLoaded={setScriptLoaded}
               actionName={shortCode} />
  </>
};

export default EFTPayment;