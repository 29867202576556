import * as Yup from "yup";
import {
    AMOUNT_TYPE_MESSAGE,
    MAX_AMOUNT_MESSAGE,
    MIN_AMOUNT_MESSAGE,
    REQUIRED_MESSAGE
} from "../../constants/validation-messages";


Yup.addMethod(Yup.number, 'validateFlexRange', function (flexRange) {
  return this.test({
    name: 'validateFlexRange',
    exclusive: true,
    message: `Amount must be between $${flexRange.minimum} and $${flexRange.maximum}`,
    test: value => {
      return !(!value || value < flexRange.minimum || value > flexRange.maximum);
    }
  });
});

export const flexSchema = (flexRange) => Yup.object().shape({
  amount: Yup.number()
    .typeError(AMOUNT_TYPE_MESSAGE)
    .min(0.01, MIN_AMOUNT_MESSAGE)
    .max(10000, MAX_AMOUNT_MESSAGE)
    .required(REQUIRED_MESSAGE)
    .test('amount-decimals', 'Must not have more than 2 decimals', amount => {
      return new RegExp(/^(\d+)(\.?)(\d){0,2}$/).test(amount);
    })
    .validateFlexRange(flexRange)
});