import {Box, Heading, Icon, Image, VStack} from "@chakra-ui/react";
import React from "react";


const Status = ({headingText, logo, icon, iconColor, children}) => {

  return(
    <VStack height={"100vh"} padding={'5vh'} gap="5vh">
      {logo && (
        <Box w={['100%', '100%', '100%', '50%']}>
          <Image alt={'merchant logo'} w={'100%'} src={logo}/>
        </Box>
      )}

      <Heading fontSize={30} textAlign={"center"}>
        {headingText}
      </Heading>
      {children}
      {icon && <Icon boxSize="150" as={icon} color={iconColor}></Icon>}
    </VStack>
  )

}

export default Status;