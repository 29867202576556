import {HStack, Image, Text, VStack} from "@chakra-ui/react";
import content from "../../constants/en";
import c2pLogo from '../../common/assets/Clik2payLogoColour.svg';
import bankPaymentsIcon from '../../common/assets/bankPaymentsIcon.svg';
import interacIcon from '../../common/assets/interacIcon.svg';
import PaymentSelectionOption from "./payment-selection-option";


const PaymentSelectionCard = ({availablePaymentMethods, merchantLogo, setSelectedPaymentType}) => {
    const copy = content.paymentSelection;

    return (
        <VStack  height={"100vh"}>
            <VStack maxWidth={"1000px"}>
                <HStack padding="1vh" width={"100%"} justify={"center"} paddingTop={"10vh"} paddingBottom={"3vh"}>
                    <Image w="250px" src={c2pLogo}/>
                </HStack>
                <VStack pl={"1vh"} pr={"1vh"}>
                    <HStack gap={"2vh"}>
                        <Text>
                            {copy.description}
                        </Text>
                        <Image w="30%" maxWidth={"170px"} src={merchantLogo} />
                    </HStack>
                    <HStack  w={"100%"}>
                        <Text fontFamily={"DMSans.Medium.normal"} color={"darkText"} size={"xl"} fontWeight={"600"}>
                            {copy.selectionOptionsTitle}
                        </Text>
                    </HStack>
                    {availablePaymentMethods.includes("INTERAC") && (
                        <PaymentSelectionOption
                            logo={interacIcon}
                            title={<><i>{copy.interac.title}</i>®</>}
                            description={copy.interac.description}
                            learnMoreUrl={copy.interac.learnMoreUrl}
                            learnMoreText={copy.interac.learnMoreText}
                            selectButtonText={copy.selectButtonText}
                            selectButtonAction={() => {setSelectedPaymentType("INTERAC")}}
                        />
                    )}
                    {availablePaymentMethods.includes("EFT") && (
                        <PaymentSelectionOption
                            logo={bankPaymentsIcon}
                            title={copy.eft.title}
                            description={copy.eft.description}
                            learnMoreUrl={copy.eft.learnMoreUrl}
                            learnMoreText={copy.eft.learnMoreText}
                            selectButtonText={copy.selectButtonText}
                            selectButtonAction={() => {setSelectedPaymentType("EFT")}}
                        />
                    )}
                </VStack>
            </VStack>
        </VStack>
    );
}


export default PaymentSelectionCard