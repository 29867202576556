import React from 'react';
import PropTypes from 'prop-types';
import { useField, Field } from 'formik';

export const PayerDetailsField = ({
  name,
  placeholder,
  label,
  maxLength,
  readOnly,
  isAmount,
  hidden,
  fontColour,
  outlineColour
}) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    !hidden && <div className="payer-details-field">
      <label htmlFor={name}
        style={{color: !hasError? `#${fontColour}` : ''}}
        className={`input-header ${hasError ? 'error-message-color' : ''}`}>
        {label}
      </label>
      <div className="uk-form-controls uk-inline uk-width-expand">
        <Field
          {...field}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          style={{color: !hasError&& `#${fontColour}`, border: !hasError&& `solid #${outlineColour}`}}
          className={`input-text ${ isAmount ? 'input-text-amount' : ''} ${ readOnly ? 'input-box-readonly' :'input-box'} ${ hasError ? 'error-message-color input-text-error' : ''}`}
          readOnly={readOnly}
        />
          <div className='uk-flex uk-flex-center'>{hasError ? (
              <span className='error-text'>{meta.error}</span>) : null}
          </div>
      </div>
    </div>
  );
};

PayerDetailsField.defaultProps = {
  placeholder: undefined,
  maxLength: undefined,
  readOnly: false,
  isAmount: false,
  hidden: false,
  fontColour: '',
  outlineColour: ''
};

PayerDetailsField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  isAmount: PropTypes.bool,
  hidden: PropTypes.bool,
  fontColour: PropTypes.string,
  outlineColour: PropTypes.string
};
