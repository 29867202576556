/* eslint-disable no-template-curly-in-string */
const content = {
    paymentSelection: {
        description: 'To provide you greater choice and flexibility, we now use Clik2pay to process our payments.',
        selectionOptionsTitle: 'How would you like to pay?',
        interac: {
            title: 'Interac ',
            description: 'Pay now, direct from any bank in Canada with no payment fees.',
            learnMoreText: 'Learn more',
            learnMoreUrl: 'https://google.ca?test=Interac'
        },
        eft: {
            title: 'Automatic bank payments',
            description: 'Conveniently pay bills automatically when they\'re due, with no fees.',
            learnMoreText: 'Learn more',
            learnMoreUrl: 'https://google.ca?test=EFT'
        },
        selectButtonText: 'Select'
    }
}

export default content;