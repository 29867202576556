import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ConfirmSmartCode from "./fulfillment/confirm-smart-code";
import ShortUrl from "./fulfillment/short-url";
import Error404 from "./error/error404";
import XeroPayment from "./fulfillment/xero/xero-payment";
import EftSignup from "./fulfillment/eft/eft-payment";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/r/:shortCode'}>
          <ShortUrl/>
        </Route>
        <Route path={'/smart/:smartCode'}>
          <ConfirmSmartCode/>
        </Route>
        <Route path={'/xero'}>
          <XeroPayment />
        </Route>
        <Route path={'/pay/:shortCode/eft'}>
          <EftSignup/>
        </Route>
        <Route path='/'>
          <Error404 />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
