import React from 'react';
import {createRoot} from 'react-dom/client';
import UIkit from 'uikit/dist/js/uikit.min';
import Icons from 'uikit/dist/js/uikit-icons';
import * as Sentry from "@sentry/react";
import App from './app';
import Error500 from "./error/error500";

import './index.scss';
import './theme/index.scss';
import {ChakraProvider} from "@chakra-ui/react";
import Fonts from "./common/fonts/fonts";
import {clik2payBaseTheme} from './common/css/clik2payTheme';

UIkit.use(Icons);

Sentry.init({
  dsn: window.config.SENTRY_DSN,
  environment: window.config.ENVIRONMENT
});


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<Error500 />}>
      <ChakraProvider theme={clik2payBaseTheme}>
          <Fonts />
          <App />
      </ChakraProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
