import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import * as Sentry from "@sentry/react";
import Loading from "../../loading";
import Recaptcha from "../../components/recaptcha/recaptcha";
import {XERO_PAYMENT_ERROR_CODE, XeroPaymentError} from "./xero-payment-error";

const PAYMENT_REQUEST_FULFILL_URL = `${window.config.XERO_API}/v1/payment-requests`

const AMOUNT_PARAM = "amount"
const INVOICE_NUM_PARAM = "invoiceNo"
const CURRENCY_PARAM = "currency"
const MERCHANT_SHORT_CODE_PARAM = "shortCode"
const RECAPTCHA_ACTION_NAME = "xero_payment"

function useQueryParams() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function XeroPayment() {
  const query = useQueryParams()
  const [errorStatusCode, setErrorStatusCode] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  const recaptchaRef = React.useRef(null);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  const setRecaptchaScriptLoaded = useCallback(() => {
    setRecaptchaLoaded(true)
  }, [])

  const handleUnexpectedError = useCallback((xeroPaymentAPIError) => {
    setErrorStatusCode(xeroPaymentAPIError.code ? xeroPaymentAPIError.code : XERO_PAYMENT_ERROR_CODE.INTERNAL_ERROR);
    console.error(xeroPaymentAPIError)
    Sentry.captureException(new Error(`Unexpected error handling Xero payment link: ${xeroPaymentAPIError.message}`, { cause: xeroPaymentAPIError }));
  }, [])

  useEffect(() => {
    if(!recaptchaLoaded) {
      return
    }

    const amount = query.get(AMOUNT_PARAM)
    const invoiceNumber = query.get(INVOICE_NUM_PARAM)
    const currency = query.get(CURRENCY_PARAM)
    const shortCode = query.get(MERCHANT_SHORT_CODE_PARAM)

    const executeAPI = async () => {
      try {
        const recaptchaToken = await recaptchaRef.current();
        const data = await createPaymentRequest(amount, invoiceNumber, currency, shortCode, recaptchaToken);
        setShowLoader(false)
        window.location.replace(data.paymentLink);
      } catch (err) {
        handleUnexpectedError(err)
      }
    }

    executeAPI().then()
  }, [recaptchaLoaded, query, setErrorStatusCode, setShowLoader, handleUnexpectedError])

  if(errorStatusCode) {
    return <XeroPaymentError errorCode={errorStatusCode} />
  }

  return (
    <>
      { showLoader &&
        <Loading />
      }
      <Recaptcha recaptchaRef={recaptchaRef}
                 setScriptLoaded={setRecaptchaScriptLoaded}
                 actionName={RECAPTCHA_ACTION_NAME} />
    </>
  )
}

async function createPaymentRequest(amount, invoiceNumber, currency, shortCode, recaptchaToken) {
  const bodyContent = {
    "invoiceNumber": invoiceNumber,
    "amount": amount,
    "currency": currency,
    "merchantShortCode": shortCode
  }

  const resp = await fetch(PAYMENT_REQUEST_FULFILL_URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-recaptcha-token": recaptchaToken,
    },
    body: JSON.stringify(bodyContent),
  })

  if (!resp.ok) {
    const body = await resp.json()
    throw new XeroPaymentAPIError(`Invalid response status creating payment request: ${resp.status}. Response Body: ${JSON.stringify(body)}`, body?.code)
  }
  return resp.json()
}

class XeroPaymentAPIError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

export default XeroPayment
