import React from "react";

export default function Footer() {

  const copyRightYear = new Date().getFullYear()

  return (
    <>
      Copyright &copy; {copyRightYear} Foghorn Payments Inc. The Clik2pay button and "2lock" design are trademarks of Foghorn Payments Inc.
      INTERAC e-Transfer™ and the INTERAC logo are owned and used with the permission of Interac Corp. Used under license.
    </>
  );
}