import {Box, Button, HStack, Image, Link, Text, VStack, Wrap, WrapItem} from "@chakra-ui/react";

const PaymentSelectionOption = ({logo, title, description, learnMoreUrl, learnMoreText, selectButtonText, selectButtonAction}) => {
    return (
        <Box background={"muted.200"} p={"1vh"} borderRadius={"1vh"} w={"100%"}>
            <HStack gap={"0.5vh"}>
                <VStack minWidth={"12.5%"} maxWidth={"60px"} alignSelf={"start"}>
                    <Image minWidth={"50px"} width={"60px"} src={logo} />
                </VStack>
                <HStack justify={"space-between"} width={"100%"}>
                    <VStack align={"left"}>
                        <Text fontFamily={"DMSans.bold"} color={"darkText"} size={"md"} fontWeight={"600"}>
                            {title}
                        </Text>
                        <Wrap>
                            <WrapItem>
                                <Text>
                                    {description}
                                </Text>
                            </WrapItem>
                            <WrapItem>
                                <Link href={learnMoreUrl}>
                                    {learnMoreText}
                                </Link>
                            </WrapItem>
                        </Wrap>
                    </VStack>
                    <VStack alignSelf={"end"}>
                        <Button background={"primary.0"} color={"white"} borderColor={"primary.0"} _hover={{ bg: "primary.1", color: "white" }} onClick={selectButtonAction}>
                            {selectButtonText}
                        </Button>
                    </VStack>
                </HStack>
            </HStack>
        </Box>
    );
}

export default PaymentSelectionOption;