import PropTypes from "prop-types";
import React, {useState} from "react";
import Loading from "../../../loading";
import useFlinksMessageListener from "./useFlinksMessageListener";

export const FLINKS_ACCOUNT_SELECTOR_CURRENCY = Object.freeze({
    USD: "usd",
    CAD: "cad"
})

const flinksHostname = window.config.FLINKS.hostname
const isDemo = window.config.FLINKS.isDemo
const defaultTermsUrl = window.config.FLINKS.termsUrl

const Flinks = ({headerEnable, consentEnable, accountSelectorEnable, accountSelectorCurrency, merchantTermsUrl, tags, onComplete, onError}) => {
    const [isLoading, setIsLoading] = useState(true);
    const termsUrl = (merchantTermsUrl && merchantTermsUrl !== "") ? merchantTermsUrl : defaultTermsUrl;

    const onloadComplete = () => setIsLoading(false);
    useFlinksMessageListener(["APP_MOUNTED"], onloadComplete)
    useFlinksMessageListener(["REDIRECT"], onComplete)
    useFlinksMessageListener(["APP_OFFLINE", "INSTITUTION_NOT_AVAILABLE", "INVALID_INSTITUTION"], onError)

    const getTagString = () => Object.entries(tags).map(([key, value]) => `${key}=${value}`).toString()

    const flinksUrlParams = new URLSearchParams();
    flinksUrlParams.append("demo", isDemo.toString());
    flinksUrlParams.append("headerEnable", headerEnable.toString());
    flinksUrlParams.append("accountSelectorEnable", accountSelectorEnable.toString());
    flinksUrlParams.append("accountSelectorCurrency", accountSelectorCurrency);
    flinksUrlParams.append("termsUrl", termsUrl);
    flinksUrlParams.append("customerName", "Clik2Pay");
    flinksUrlParams.append("consentEnable", consentEnable.toString());

    if (tags) {
        flinksUrlParams.append("tag", getTagString());
    }

    return (
        <>
            {isLoading && <Loading/>}
            <iframe title="account-linking" height="100%" width="100%"
                    src={`https://${flinksHostname}/v2/?${flinksUrlParams.toString()}`}>
            </iframe>
        </>
    );
};

Flinks.defaultProps = {
    headerEnable: true,
    accountSelectorEnable: true,
    consentEnable: false,
    accountSelectorCurrency: FLINKS_ACCOUNT_SELECTOR_CURRENCY.CAD,
    onComplete: () => {},
    onError: () => {},
    tags: {},
};

Flinks.propTypes = {
    headerEnable: PropTypes.bool,
    consentEnable: PropTypes.bool,
    accountSelectorEnable: PropTypes.bool,
    accountSelectorCurrency: PropTypes.oneOf([FLINKS_ACCOUNT_SELECTOR_CURRENCY.CAD, FLINKS_ACCOUNT_SELECTOR_CURRENCY.USD]),
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    tags: PropTypes.object,
};

export default Flinks;