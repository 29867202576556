import React from "react";

function Error404() {
  return (
    <div className="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
      <h1>404</h1>
      <span>The requested resource has not been found.</span>
    </div>
  )
}

export default Error404
