import React from "react";
import PropTypes from 'prop-types';
import {Flex, Image, Text} from "@chakra-ui/react";
import Logo from '../../common/assets/Clik2payLogoColour.svg';

export const XeroPaymentError = ({
    errorCode
 }) => {
    let titleMessage;
    let bodyMessage = null;

    if(errorCode === XERO_PAYMENT_ERROR_CODE.INVOICE_PAID) {
        titleMessage = <>This invoice has been paid</>;
    } else if (errorCode === XERO_PAYMENT_ERROR_CODE.BAD_REQUEST) {
        titleMessage = <>There was a problem with<br/>your payment request</>;
        bodyMessage = <>Please contact your merchant</>;
    } else {
        titleMessage = <>There was an error loading<br/>your payment information</>;
        bodyMessage = <>Please close this page and try again</>;
    }

    return (
        <Flex size='md' justify={'center'} align={'center'} direction={'column'}>
            <Image src={Logo} maxW='xs' mt={'200px'}/>
            <Text align={'center'} fontSize={'3xl'} mt={'100px'} >{titleMessage}</Text>
            <Text fontSize={'md'} mt={'30px'}>{bodyMessage}</Text>
        </Flex>
    );
}

export const XERO_PAYMENT_ERROR_CODE = {
    BAD_REQUEST: "BAD_REQUEST",
    NOT_FOUND: "NOT_FOUND",
    FORBIDDEN: "FORBIDDEN",
    INTERNAL_ERROR: "INTERNAL_ERROR",
    INVOICE_PAID: "INVOICE_PAID",
    INVOICE_AMOUNT_MISMATCH: "INVOICE_AMOUNT_MISMATCH",
    INVOICE_LOOKUP_FAILED: "INVOICE_LOOKUP_FAILED",
    CONNECTION_INVALID: "CONNECTION_INVALID"
}

XeroPaymentError.propTypes = {
    errorCode: PropTypes.oneOf(Object.values(XERO_PAYMENT_ERROR_CODE)).isRequired,
};

