import {useCallback, useEffect, useState} from "react";
import * as Sentry from "@sentry/react";
import {PAYMENT_TYPE} from "../../fulfillment/model/payment-request";

const PAYMENT_REQUEST_FULFILL_URL = `${window.config.FULFILLMENT_API}/payment-requests`

const usePaymentRequest = (shortCode, setErrorStatusCode, recaptchaLoaded, recaptchaRef) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [flexDetails, setFlexDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleUnexpectedError = useCallback((error) => {
    setErrorStatusCode(500);
    Sentry.captureException(error);
  }, [setErrorStatusCode])

  const fetchPaymentRequestForShortCode = useCallback(async (token) => {
    try {
      const resp = await fetch(`${PAYMENT_REQUEST_FULFILL_URL}/${shortCode}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": token,
        },
      });

      if (!resp.ok) {
        setErrorStatusCode(resp.status);
        return;
      }

      const data = await resp.json()

      setPaymentRequest(data)
      if(data && data.type === PAYMENT_TYPE.FLEX) {
        const formFlexDetails = {
          ...data.flexDetails,
          billerLogo: data.billerLogo
        }
        setFlexDetails(formFlexDetails)
      }
    }
    catch (err) {
      handleUnexpectedError(err)
    } finally {
      setIsLoading(false)
    }
  }, [shortCode, setPaymentRequest, setErrorStatusCode, handleUnexpectedError])

  useEffect(() => {
    if(!recaptchaLoaded) {
      return
    }

    const fetchDataWithRecaptcha = async () => {
      try {
        const token = await recaptchaRef.current();
        await fetchPaymentRequestForShortCode(token)
      } catch (err) {
        handleUnexpectedError(err)
      }
    }
    fetchDataWithRecaptcha().then()
  }, [recaptchaLoaded, recaptchaRef, fetchPaymentRequestForShortCode, handleUnexpectedError])

  return {
    isLoading,
    paymentRequest,
    flexDetails,
  }
}

export default usePaymentRequest