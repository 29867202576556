import {useEffect} from "react";


const eventType = 'message';
const useFlinksMessageListener = (messageNames, onMessageReceived) => {
    useEffect(() => {
        const onMessage = (event) => {
            if (messageNames.includes(event.data?.step)){
                onMessageReceived(event.data)
            }
        };
        window.addEventListener(eventType, onMessage);

        return () => {
            window.removeEventListener(eventType, onMessage);
        }
    }, [messageNames, onMessageReceived]);
};

export default useFlinksMessageListener;