import Status from "./status";
import {NumericFormat} from 'react-number-format';
import {Text} from "@chakra-ui/react";
import React from "react";

const EFTPaymentSuccess = ({paymentRequest}) => (
  <Status headingText={"Success!"} logo={paymentRequest.billerLogo}>
    <Text fontSize={20} textAlign={"center"}>
      You have successfully completed a payment of
      <NumericFormat
        value={paymentRequest.amount}
        displayType="text"
        thousandSeparator
        prefix=" $"
        suffix=" "
        decimalScale={2}
        fixedDecimalScale={true}
      />
      for your account {paymentRequest.merchantAccountId} through your bank account.
    </Text>
  </Status>
)

export default EFTPaymentSuccess;
