import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Error404 from '../error/error404';
import Loading from '../loading';
import Footer from '../components/footer/footer';
import {Form, Formik} from 'formik';
import {INITIAL_PAYER_DETAILS} from './model/payer-details';
import {MAX_AMOUNT_MESSAGE, MAX_MESSAGE, MIN_AMOUNT_MESSAGE, MIN_MESSAGE, REGEXP_ACCOUNT_ID_MESSAGE, REGEXP_NAME_MESSAGE, REQUIRED_MESSAGE} from '../constants/validation-messages';
import {PayerDetailsField} from './components/payer-details-field';
import C2pLogo from '../common/assets/clik2payViaInterac.svg';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import './confirm-smart-code.scss';
import Error500 from '../error/error500';

const recaptchaRef = React.createRef();

const ACCOUNT_ID_REGEX = /^[a-zA-Z0-9]+$/
const DEFAULT_MINIMUM_AMOUNT = 0.01;
const DEFAULT_MAXIMUM_AMOUNT = 10000.00;

export const getConfirmSmartLinkSchema = (minAmount, maxAmount) => Yup.object().shape({
    amount: Yup.number()
        .min(minAmount, MIN_AMOUNT_MESSAGE)
        .max(maxAmount, MAX_AMOUNT_MESSAGE)
        .required(REQUIRED_MESSAGE)
        .test('amount-decimals', 'Must not have more than 2 decimals', amount => {
            return new RegExp(/^(\d+)(\.?)(\d){0,2}$/).test(amount);
        }),
    customer_name: Yup.string()
        .max(100, MAX_MESSAGE)
        .min(3, MIN_MESSAGE)
        .matches(
            /^[a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ '\-.]+$/,
            REGEXP_NAME_MESSAGE
        )
        .required(REQUIRED_MESSAGE),
    customer_email: Yup.string()
        .min(5, MIN_MESSAGE)
        .max(64, MAX_MESSAGE)
        .matches(
            /^[\w!#$%&’*+/=?`{|}~^-]+(?:\.[\w!#$%&’*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
            'Must be a valid email'
        )
        .required(REQUIRED_MESSAGE),
    account_id: Yup.string()
        .max(50, MAX_MESSAGE)
        .min(4, MIN_MESSAGE)
        .matches(
            ACCOUNT_ID_REGEX,
            REGEXP_ACCOUNT_ID_MESSAGE
        )
});

export const getConfirmSmartLinkNoEmailSchema = (minAmount, maxAmount) => Yup.object().shape({
    amount: Yup.number()
        .min(minAmount, MIN_AMOUNT_MESSAGE)
        .max(maxAmount, MAX_AMOUNT_MESSAGE)
        .required(REQUIRED_MESSAGE)
        .test('amount-decimals', 'Must not have more than 2 decimals', amount => {
            return new RegExp(/^(\d+)(\.?)(\d){0,2}$/).test(amount);
        }),
    customer_name: Yup.string()
        .max(100, MAX_MESSAGE)
        .min(3, MIN_MESSAGE)
        .matches(
            /^[a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ '\-.]+$/,
            REGEXP_NAME_MESSAGE
        )
        .required(REQUIRED_MESSAGE),
    account_id: Yup.string()
        .max(50, MAX_MESSAGE)
        .min(4, MIN_MESSAGE)
        .matches(
            ACCOUNT_ID_REGEX,
            REGEXP_ACCOUNT_ID_MESSAGE
        )
});

const getSmartLinkFulfillmentUrl = (smartLinkId) => `${window.config.FULFILLMENT_API}/smart-link/${smartLinkId}/fulfillment`

export const fulfillSmartLinkRequest = (values, reCaptchaValue, smartLinkId) => {
    fetch(getSmartLinkFulfillmentUrl(smartLinkId), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "x-recaptcha-token": reCaptchaValue,
        },
        body: JSON.stringify ({
            amount: values.amount,
            customerName: values.customer_name,
            customerEmail: values.customer_email ? values.customer_email: null,
            merchantAccountId: values.account_id ? values.account_id: null,
        })
    }).then(function (resp) {
        if (!resp.ok) {
            window.location.replace("/error/" + resp.status);
        } else {
            resp.json().then(function (data) {
                window.location.replace(data.paymentLink);
            });
        }
    });
}

export const queryRecaptcha = async () => {
    return await recaptchaRef.current.executeAsync()
}

function ConfirmSmartCode() {
  return (
    <>
      <SmartFormContainer />
      <ReCAPTCHA ref={recaptchaRef} sitekey={window.config.RECAPTCHA_PUBLIC_KEY} size='invisible'>
      </ReCAPTCHA>
    </>
  )
}

function SmartFormContainer() {
    return (
        <div className='payer-details uk-grid uk-grid-collapse uk-height-viewport'>
            <div className='payer-details-side uk-width-visible@s ' />
            <div className='uk-width-2-3@m'>
                <DynamicSmartForm />
            </div>
            <div className='payer-details-side uk-width-visible@s' />
        </div>
    )
}

function DynamicSmartForm() {
  const { smartCode } = useParams()
  const [smartLinkDetails, setSmartLinkDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError ] = useState(null)

  const smartLinkEndpoint = `${window.config.FULFILLMENT_API}/smart-link/${smartCode}`


  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitted(true)
    let token = await queryRecaptcha()
    fulfillSmartLinkRequest(values, token, values.smartLinkId);
    setSubmitting(false);
  }

  const autoSubmit = async (smartLinkId) => {
      setSubmitted(true)
      let token = await queryRecaptcha()
      fulfillSmartLinkRequest({}, token, smartLinkId);
  }

  useEffect(() => {
    queryRecaptcha()
      .then(recaptchaToken =>
        fetch(smartLinkEndpoint, {
          headers: {
            'Content-Type': 'application/json',
            'x-recaptcha-token': recaptchaToken,
          },
        })
      )
      .then(res => res.json())
      .then(
        (result) => {
          setSmartLinkDetails(result);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          setError(error);
          Sentry.captureException(error);
        });
  }, [smartLinkEndpoint])

  if (loading || submitted) {
    return <Loading />
  }

  if (!smartLinkDetails) {
    return <Error404 />
  }

  if (error) {
      return <Error500 />
  }

  const defaultCustomerName = smartLinkDetails.defaultCustomerName ? smartLinkDetails.defaultCustomerName : INITIAL_PAYER_DETAILS.customer_name
  const defaultEmail = smartLinkDetails.defaultEmail ? smartLinkDetails.defaultEmail : INITIAL_PAYER_DETAILS.customer_email
  const defaultAmount = smartLinkDetails.defaultAmount ? smartLinkDetails.defaultAmount : INITIAL_PAYER_DETAILS.amount
  const minimumAmount = smartLinkDetails.minimumAmount?.toFixed(2) ?? DEFAULT_MINIMUM_AMOUNT;
  const maximumAmount = smartLinkDetails.maximumAmount?.toFixed(2) ?? DEFAULT_MAXIMUM_AMOUNT;

  const hideEmailField = smartLinkDetails.defaultMobileNumber !== null && smartLinkDetails.defaultEmail === null
  const smartLinkId = smartLinkDetails.id

  const initialValues = {
      ...INITIAL_PAYER_DETAILS,
      customer_name: defaultCustomerName,
      customer_email: defaultEmail,
      amount: defaultAmount,
      smartLinkId: smartLinkId
  }

  if (defaultCustomerName !== INITIAL_PAYER_DETAILS.customer_name
    && (defaultEmail !== INITIAL_PAYER_DETAILS.customer_email || smartLinkDetails.defaultMobileNumber !== null)
    && defaultAmount !== INITIAL_PAYER_DETAILS.amount) {
      autoSubmit(smartLinkId).then()
  }

  return (
      <>
          <div className='uk-flex uk-flex-center uk-grid-margin-large uk-padding-small'>
            <div className='uk-width-xlarge merchant-logo'>
              <img src={`${smartLinkDetails.billerLogo}`} alt=""/>
            </div>
          </div>
          <div className='payer-details-title'>
            <div className='payer-details-title-text'>
              Please confirm any remaining details to complete your transaction
            </div>
          </div>
          <Formik
              initialValues={initialValues}
              validationSchema={hideEmailField ? getConfirmSmartLinkNoEmailSchema(minimumAmount, maximumAmount) : getConfirmSmartLinkSchema(minimumAmount, maximumAmount)}
              onSubmit={onSubmit}
          >
            {({ errors, isSubmitting}) => (
                <Form className="uk-form-stacked">
                  <PayerDetailsField
                      name="customer_name"
                      label="Full Name"
                      maxLength={100}
                      placeholder=""
                      required
                      readOnly = {defaultCustomerName !== INITIAL_PAYER_DETAILS.customer_name}
                  />
                  <PayerDetailsField
                      name="customer_email"
                      label="Email Address"
                      placeholder=""
                      required
                      maxLength={64}
                      readOnly = {defaultEmail !== INITIAL_PAYER_DETAILS.customer_email}
                      hidden = {hideEmailField}
                  />
                  <PayerDetailsField
                      name="amount"
                      label="Amount"
                      placeholder="$0.00"
                      errors={errors}
                      required
                      readOnly = {defaultAmount !== INITIAL_PAYER_DETAILS.amount}
                      isAmount
                  />
                <PayerDetailsField
                    name="account_id"
                    label="Account ID (Optional)"
                    placeholder=""
                    errors={errors}
                />
                  <div className="button-groups uk-button-group uk-align-center">
                    <button
                        className="submit uk-button uk-background-default uk-align-center uk-width-2-3 "
                        type="submit"
                        disabled={isSubmitting}
                    >
                      <img src={C2pLogo} className="img-c2p" alt="Clik2pay logo"/>
                    </button>
                  </div>
                </Form>
            )}
          </Formik>
          <div className="payer-details-copyright">
            <Footer />
          </div>
      </>
  )
}

export default ConfirmSmartCode
