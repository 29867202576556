import { INITIAL_FLEX_DETAILS } from "../../model/flex-payment-details";
import { Form, Formik } from "formik";
import Footer from '../../../components/footer/footer';
import { flexSchema } from "../../../common/schema/flex-form-schema";
import { PayerDetailsField } from "../payer-details-field";
import { ReactComponent as Logo } from '../../../common/assets/clik2payViaInterac.svg';
import { flexDetailsType } from "../../../common/types/merchant/flex-details-type";
import PropTypes from "prop-types";

export default function DynamicFlexForm({flexDetails, submitFlexPayment}) {

  const initialValues = {
    ...INITIAL_FLEX_DETAILS,
    amount: flexDetails.requested.toFixed(2)
  }

  const { brandingDetails } = flexDetails;
  const borderOutLineColor = brandingDetails.boxOutlineColour;
  const fontColor = brandingDetails.fontColour;

  const onSubmit = async (values, { setSubmitting }) => {
    submitFlexPayment(values)
    setSubmitting(false);
  }

  return (
    <div className='flex-details uk-grid uk-grid-collapse uk-height-viewport'>
      <div className='flex-details-side uk-width-visible@s ' />
      <div className='uk-width-2-3@m'>
        <div className='uk-flex uk-flex-center uk-grid-margin-large uk-padding-small'>
          <div className='uk-width-xlarge' style={{display: 'flex', justifyContent: 'center'}}>
            <img src={`${flexDetails.billerLogo}`} alt=""/>
          </div>
        </div>
        <div className='flex-details-title' style={{border: `solid #${borderOutLineColor}`}}>
          <div className='flex-details-title-text' style={{color: `#${fontColor}`}}>
            {brandingDetails.message}
          </div>
        </div>
        <Formik
          initialValues={ initialValues }
          validationSchema={ () => flexSchema({minimum: flexDetails.minimum, maximum: flexDetails.maximum}) }
          onSubmit={ onSubmit }
        >
          {({ errors, isSubmitting}) => (
            <Form className="uk-form-stacked">
              <PayerDetailsField
                name="amount"
                label="Amount"
                placeholder="$0.00"
                errors={errors}
                required
                isAmount
                fontColour={fontColor}
                outlineColour={borderOutLineColor}
              />
              <div className="button-groups uk-button-group uk-align-center">
                <button
                    className="submit uk-button uk-background-default"
                    type="submit"
                    disabled={isSubmitting}
                >
                    <Logo className="img-c2p" alt="Clik2pay logo"/>
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="flex-details-copyright">
          <Footer />
        </div>
        </div>
        <div className='flex-details-side uk-width-visible@s' />
    </div>
  )
}

DynamicFlexForm.propTypes = {
  flexDetails: flexDetailsType.isRequired,
  submitFlexPayment: PropTypes.func.isRequired
};