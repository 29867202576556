import Status from "./status";
import {Heading, Text} from "@chakra-ui/react";
import React from "react";
import {MdCancel} from "react-icons/md";

const EFTPaymentFailure = ({paymentRequest}) => (
  <Status headingText={"We experienced an issue"} logo={paymentRequest.billerLogo} icon={MdCancel} iconColor="#f24725">
    <Text fontSize={20} textAlign={"center"}>
      There was an issue processing your payment
    </Text>
    <Heading fontSize={20} textAlign={"center"} fontWeight="bold" >
      Please try again through the payment link
    </Heading>
  </Status>
)

export default EFTPaymentFailure;