import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Recaptcha from "../components/recaptcha/recaptcha";
import DynamicFlexForm from "../fulfillment/components/flex/dynamic-flex-form";
import Loading from "../loading";
import Error500 from "../error/error500";
import Error404 from "../error/error404";
import * as Sentry from "@sentry/react";
import { PAYMENT_TYPE } from "./model/payment-request";
import "./short-url.scss";
import PaymentSelectionCard from "./paymentselection/payment-selection-card";

const PAYMENT_REQUEST_FULFILL_URL = `${window.config.FULFILLMENT_API}/payment-requests`

function ShortUrl() {
  const { shortCode } = useParams();
  const recaptchaRef = React.useRef(null);
  const [errorStatusCode, setErrorStatusCode] = useState(null);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [flexDetails, setFlexDetails] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(null);

  const setScriptLoaded = useCallback(() => {
    setRecaptchaLoaded(true)
  }, [setRecaptchaLoaded])

  const handleUnexpectedError = useCallback((error) => {
    setErrorStatusCode(500);
    Sentry.captureException(error);
  }, [setErrorStatusCode])

  const fetchPaymentRequestForShortCode = useCallback(async (token) => {
    try {
      const resp = await fetch(`${PAYMENT_REQUEST_FULFILL_URL}/${shortCode}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": token,
        },
      });

      if (!resp.ok) {
        setErrorStatusCode(resp.status);
        return;
      }

      const data = await resp.json()
      if (data.paymentLink) {
        window.location.replace(data.paymentLink);
        return;
      }

      setPaymentRequest(data)
      if(data && data.type === PAYMENT_TYPE.FLEX) {
        const formFlexDetails = {
          ...data.flexDetails,
          billerLogo: data.billerLogo
        }
        setFlexDetails(formFlexDetails)
        setShowLoader(false)
      }
      const responseAvailablePaymentMethods = data.availablePaymentMethods;
      setAvailablePaymentMethods(responseAvailablePaymentMethods);
      if(responseAvailablePaymentMethods.length === 1) {
        setSelectedPaymentMethod(responseAvailablePaymentMethods[0])
      } else {
        // Show payment selection card
        setShowLoader(false);
      }
    }
    catch (err) {
      handleUnexpectedError(err)
    }
  }, [shortCode, setPaymentRequest, setErrorStatusCode, handleUnexpectedError, setAvailablePaymentMethods])

  const getFetchPostConfig = (token, formData) => {
    // if formData has value then stringified and appended to api call body
    if(formData && Object.keys(formData).length === 0 && Object.getPrototypeOf(formData) === Object.prototype) {
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": token
        }
      }
    } else {
      return {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-recaptcha-token": token
        },
        body: JSON.stringify(formData),
      }
    }
  }

  const fulfillPaymentRequest = useCallback(async (token, amount = null, paymentMethod = "INTERAC") => {
    const body = {
      amount: amount,
      paymentMethod: paymentMethod
    };
    try {
      const resp = await fetch(`${PAYMENT_REQUEST_FULFILL_URL}/${paymentRequest.id}/fulfillment`, getFetchPostConfig(token, body))

      if (!resp.ok) {
        setErrorStatusCode(resp.status);
        return;
      }

      const data = await resp.json();
      window.location.replace(data.paymentLink);
    }
    catch (err) {
      handleUnexpectedError(err)
    }
  }, [paymentRequest, setErrorStatusCode, handleUnexpectedError])

  // Once the amount value is set in flex payment form the dynamicFlexForm component calls this method to fulfill
  const submitFlexPayment = (formData) => {
    setShowLoader(true)
    submitFulfillment(formData, selectedPaymentMethod).then()
  }

  const submitFulfillment = useCallback(async (formData = {}, paymentMethod) => {
    try {
      const token = await recaptchaRef.current();
      await fulfillPaymentRequest(token, formData?.amount, paymentMethod)
    } catch (err) {
      handleUnexpectedError(err)
    }
  }, [recaptchaRef, fulfillPaymentRequest, handleUnexpectedError])

  // Once the recaptcha script is loaded, get a token and use it to fetch the payment request
  useEffect(() => {
    if(!recaptchaLoaded) {
      return
    }

    const fetchDataWithRecaptcha = async () => {
      try {
        const token = await recaptchaRef.current();
        await fetchPaymentRequestForShortCode(token)
      } catch (err) {
        handleUnexpectedError(err)
      }
    }
    fetchDataWithRecaptcha().then()
  }, [recaptchaLoaded, recaptchaRef, fetchPaymentRequestForShortCode, handleUnexpectedError])

  // Once the payment request is loaded make the call to complete fulfillment
  useEffect(() => {
    if(!paymentRequest) {
      return;
    }

    if(paymentRequest.type !== PAYMENT_TYPE.FLEX && selectedPaymentMethod !== null) {
      submitFulfillment(null, selectedPaymentMethod).then()
    }
  }, [paymentRequest, submitFulfillment, selectedPaymentMethod])

  if(errorStatusCode === 404) {
    return <Error404 />
  }

  if(errorStatusCode) {
    return <Error500 />
  }

  return (
    <>
      { showLoader &&
        <Loading />
      }
      { !showLoader && flexDetails && selectedPaymentMethod &&
        <DynamicFlexForm
          submitFlexPayment={submitFlexPayment}
          flexDetails={flexDetails} />
      }
      { !showLoader && paymentRequest && selectedPaymentMethod == null && availablePaymentMethods &&
          <PaymentSelectionCard availablePaymentMethods={availablePaymentMethods} setSelectedPaymentType={setSelectedPaymentMethod} merchantLogo={paymentRequest.billerLogo}/>
      }
      <Recaptcha recaptchaRef={recaptchaRef}
        setScriptLoaded={setScriptLoaded}
        actionName={shortCode} />
    </>
  )
}

export default ShortUrl