import { extendTheme } from "@chakra-ui/react";

export const clik2payBaseTheme = extendTheme({
    styles: {
        global: {
            "*": {
                boxSizing: "inherit"
            },
            "*[data-focus]": {
                boxShadow: "none"
            },
            "html, body": {
                color: '#333',
                "WebkitFontSmoothing": "inherit"
            },
            "svg": {
                display: "inherit"
            },
            "a": {
                color: "#1e87f0"
            },
            "h1, h2, h3, h4, h5, h6": {
                margin: "0 0 20px 0"
            },
            "h3": {
                fontSize: '2xl'
            },
            "h1": {
                fontSize: '4xl'
            }
        },
    },
    colors: {
        darkText: '#27272a',
        primary: {
            0: "#7b3bad",
            1: "#5f32b7",
            50: '#f4eaff',
            100: '#dac1f1',
            200: '#c09ae5',
            300: '#a671d9',
            400: '#8d4ace',
            500: '#7240a7',
            600: '#5a268d',
            700: '#401a65',
            800: '#270f3e',
            900: '#0f0319'
        },
        secondary: {
            1: '#310067',
            2: '#08e6d8',
            21: 'rgba(8,230,216,0.1)'
        },
        tertiary: {
            1: '#04AFFD',
            2: '#ffa61d',
            3: '#cc183c',
            4: '#dcdce6'
        },
        muted: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#e5e5e5',
            300: '#d4d4d4',
            400: '#a3a3a3',
            500: '#737373',
            600: '#525252',
            700: '#404040',
            800: '#262626',
            900: '#171717'
        },
        warning: {
            50: '#fff7ed',
            100: '#ffedd5',
            200: '#fed7aa',
            300: '#fdba74',
            400: '#fb923c',
            500: '#f97316',
            600: '#ea580c',
            700: '#c2410c',
            800: '#9a3412',
            900: '#7c2d12'
        },
        error: {
            50: '#fef2f2',
            100: '#fee2e2',
            200: '#fecaca',
            300: '#fca5a5',
            400: '#f87171',
            500: '#ef4444',
            600: '#dc2626',
            700: '#b91c1c',
            800: '#991b1b',
            900: '#7f1d1d'
        },
        info: {
            50: '#f0f9ff',
            100: '#e0f2fe',
            200: '#bae6fd',
            300: '#7dd3fc',
            400: '#38bdf8',
            500: '#0ea5e9',
            600: '#0284c7',
            700: '#0369a1',
            800: '#075985',
            900: '#0c4a6e'
        },
        teal: {
            0: '#0369A1'
        }
    },
    fonts: {
        body: `"Lato" , sans-serif`,
        html: `"Lato" , sans-serif`,
        DMSans: {
            400: {
                normal: "DMSans",
                italic: "DMSans-Italic",
            },
            500: {
                normal: "DMSans-Medium",
                italic: "DMSans-MediumItalic",
                bold: "DMSans-Medium"
            },
            700: {
                normal: "DMSans-Bold",
                italic: "DMSans-BoldItalic",
                bold: "DMSans-Bold"
            },
            900: {
                normal: "DMSans-Bold",
                italic: "DMSans-BoldItalic",
                bold: "DMSans-Bold"
            }
        }
    },
    components: {
        Input: {
            baseStyle: {
                field: {
                    fontFamily: 'DMSans.500.normal',
                    borderRadius: '4px',
                },
            },
            sizes: {
                xs: {
                    field: {
                        fontSize: "xs",
                        height: 6,
                        paddingX: 2,
                    },
                },
                sm: {
                    field: {
                        fontSize: "sm",
                        height: 8,
                        paddingX: 3,
                    },
                },
                md: {
                    field: {
                        fontSize: "md",
                        height: 10,
                        paddingX: 4,
                    },
                },
                lg: {
                    field: {
                        fontSize: "lg",
                        height: 12,
                        paddingX: 4,
                    },
                },
            },
            variants: {
                outline: {
                    field: {
                        border: "1px solid",
                        bg: "#fff",
                        borderColor: "inherit",
                        _focus: {
                            zIndex: 1,
                            border: "1px solid #737373",
                            borderColor: "muted.500",
                        },
                        _hover: { borderColor: "muted.200" },
                        _placeholder: {
                            color: "muted.200",
                            fontSize: 'md',
                            fontFamily: 'DMSans.400.normal'
                        }
                    },
                },
                filled: {
                    field: {
                        background: "muted.300",
                        border: "2px solid",
                        borderColor: "transparent",
                        opacity: '1 !important',
                        _focus: {
                            background: "muted.300",
                            borderColor: "transparent",
                        },
                        _hover: {
                            background: "muted.300",
                        },
                        _placeholder: {
                            color: "darkText",
                            fontSize: 'md',
                            fontFamily: 'DMSans.400.normal'
                        }
                    },
                },
                flushed: {
                    field: {
                        background: "transparent",
                        borderBottom: "1px solid",
                        borderColor: "inherit",
                        borderRadius: 0,
                        paddingX: 0,
                        _focus: {
                            border: "1px solid #737373",
                            borderColor: "muted.500",
                        },
                    },
                }
            },
            defaultProps: {
                size: "md",
                variant: "outline",
            },
        },
        FormLabel: {
            baseStyle: {
                fontFamily: 'DMSans.500.normal',
                color: 'muted.500',
                fontSize: 'md'
            }
        },
        Link: {
            baseStyle: {
                fontFamily: 'DMSans.500.normal',
                color: 'info.700',
                _hover: {
                    textDecoration: 'none',
                    color: 'info.800'
                }
            }
        },
        Heading: {
            baseStyle: {
                margin: "0 0 20px 0",
                fontFamily: 'DMSans.700.normal',
                color: 'darkText'
            }
        },
        Text: {
            baseStyle: {
                fontFamily: 'DMSans.400.normal',
                color: "muted.500",
                h4: {
                    fontFamily: 'DMSans.500.normal',
                    color: 'muted.500'
                }
            },
            sizes: {
                md: {
                    fontSize: '16px'
                },
                lg: {
                    fontSize: '18px'
                },
                xl: {
                    fontSize: '20px'
                },
                xl2: {
                    fontSize: '24px'
                }
            }
        },
        Modal: {
            baseStyle: {
                justifyContent: 'center',
                alignItems: 'center',
                dialog: {
                    bg: 'primary.0',
                    padding: '15px',
                    borderRadius: '0px'
                },
                header: {
                    textAlign: 'center',
                    mt: '25px'
                },
                body: {
                    color: 'primary.50'
                },
                closeButton: {
                    color: 'primary.50'
                },
                footer: {
                    justifyContent: 'space-around'
                }
            },
            defaultProps: {
                size: 'xl'
            }
        },
    }
});